import React, { useEffect, useRef } from 'react';
import { FaLock, FaShieldAlt, FaUserShield } from 'react-icons/fa';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Security = () => {
  const securityRef = useRef(null);

  useEffect(() => {
    gsap.from(securityRef.current, {
      duration: 1,
      opacity: 0,
      y: 50,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: securityRef.current,
        start: 'top center',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
      },
    });
  }, []);

  return (
    <div ref={securityRef} className="container mx-auto p-8 bg-white shadow-md rounded-lg">
      <h2 className="text-3xl font-bold text-center mb-6">Security Settings</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="security-item bg-gray-50 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
          <FaLock className="text-6xl text-green-500 mb-4" />
          <h4 className="text-2xl font-bold mb-2">Password Management</h4>
          <p className="text-gray-700">Change your password regularly to keep your account secure.</p>
        </div>
        <div className="security-item bg-gray-50 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
          <FaShieldAlt className="text-6xl text-green-500 mb-4" />
          <h4 className="text-2xl font-bold mb-2">Two-Factor Authentication</h4>
          <p className="text-gray-700">Enhance your account security by enabling 2FA.</p>
        </div>
        <div className="security-item bg-gray-50 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
          <FaUserShield className="text-6xl text-green-500 mb-4" />
          <h4 className="text-2xl font-bold mb-2">User Access Control</h4>
          <p className="text-gray-700">Manage who has access to your account and services.</p>
        </div>
      </div>
    </div>
  );
};

export default Security;
