// src/components/instance/RealTimeInstanceStatus.jsx
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchInstanceStatus } from '../../api/instances';
import { statusToColorClasses } from '../../util/statusStyles';

const RealTimeInstanceStatus = ({ instanceName, project, interval = 500 }) => {
  const [dynamicInterval, setDynamicInterval] = useState(interval);
  const queryClient = useQueryClient();

  const { data: status = 'Unknown', error, isFetching, refetch } = useQuery({
    queryKey: ['instanceStatus', instanceName, project],
    queryFn: async () => await fetchInstanceStatus({ instanceName, project }),
    refetchInterval: dynamicInterval,
  });

  useEffect(() => {
    if (status === 'Starting' || status === 'Stopping' || status === 'Restarting') {
      setDynamicInterval(2000);
    } else {
      setDynamicInterval(interval);
    }
  }, [status, interval]);

  if (error) {
    return <div className="status-indicator">Error fetching status</div>;
  }

  return (
    <div className={`status-indicator ${statusToColorClasses(status)} inline-block px-2 py-1 text-xs font-semibold rounded-full`}>
      {status}
    </div>
  );
};

export default RealTimeInstanceStatus;
