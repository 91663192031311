import React from 'react';
import { Link } from 'react-router-dom';
import { logoLight } from '../../util';

const NavbarLogo = () => (
  <Link to="/" className="flex items-center space-x-3" aria-label="DadiSpace Home">
    <img src={logoLight} alt="DadiSpace Logo" width={35} height={35} className="w-10 h-10" />
    <h1 className="text-3xl font-bold tracking-wide text-white hidden sm:block">DadiSpace</h1>
  </Link>
);

export default NavbarLogo;
