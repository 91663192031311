import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { menuItems } from '../../util/menuItems';

const NavbarSearch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
        setSearchQuery('');
        setSuggestions([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setSearchQuery('');
    setSuggestions([]);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setSuggestions(
      menuItems.flatMap((item) =>
        item.subItems
          ? item.subItems.filter((subItem) =>
              subItem.name.toLowerCase().includes(query.toLowerCase())
            )
          : item.name.toLowerCase().includes(query.toLowerCase())
          ? [item]
          : []
      )
    );
  };

  const suggestedServices = menuItems.slice(0, 3); // Example: First 3 items as suggested services
  const recentlyUsedServices = menuItems.slice(3, 6); // Example: Next 3 items as recently used

  return (
    <div className="relative">
      <button onClick={toggleModal} className="text-xl text-white hover:text-gray-200 transition-colors duration-300">
        <FaSearch />
      </button>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div ref={modalRef} className="bg-white rounded-lg p-6 w-11/12 md:w-1/2 lg:w-1/3">
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search services..."
                className="px-4 py-2 rounded-lg bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 flex-grow"
              />
              <button onClick={toggleModal} className="text-xl text-gray-800 hover:text-gray-600 ml-2 focus:outline-none">
                <FaTimes />
              </button>
            </div>
            {searchQuery === '' && (
              <>
                <div className="mb-4">
                  <h2 className="text-lg font-semibold text-gray-700">Suggested Services</h2>
                  <div className="overflow-y-auto max-h-60">
                    {suggestedServices.map((service) => (
                      <a key={service.name} href={service.path} className="block px-4 py-2 hover:bg-gray-100 flex items-center space-x-2 text-gray-700">
                        <service.icon className="text-green-600" />
                        <span>{service.name}</span>
                      </a>
                    ))}
                  </div>
                </div>
                <div>
                  <h2 className="text-lg font-semibold text-gray-700">Recently Used</h2>
                  <div className="overflow-y-auto max-h-60">
                    {recentlyUsedServices.map((service) => (
                      <a key={service.name} href={service.path} className="block px-4 py-2 hover:bg-gray-100 flex items-center space-x-2 text-gray-700">
                        <service.icon className="text-green-600" />
                        <span>{service.name}</span>
                      </a>
                    ))}
                  </div>
                </div>
              </>
            )}
            {suggestions.length > 0 && searchQuery && (
              <div className="overflow-y-auto max-h-60 mt-4">
                {suggestions.map((suggestion) => (
                  <a key={suggestion.name || suggestion.path} href={suggestion.path || suggestion.subItems[0].path} className="block px-4 py-2 hover:bg-gray-100 flex items-center space-x-2 text-gray-700">
                    <suggestion.icon className="text-green-600" />
                    <span>{suggestion.name}</span>
                  </a>
                ))}
              </div>
            )}
            {suggestions.length === 0 && searchQuery && (
              <div className="text-gray-500 text-center">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarSearch;
