import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const Loader = () => {
  const loaderRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      loaderRef.current,
      { rotation: 0 },
      { rotation: 360, repeat: -1, ease: 'linear', duration: 1 }
    );
  }, []);

  return (
    <div className="flex justify-center items-center h-full">
      <div ref={loaderRef} className="w-16 h-16 border-4 border-green-500 border-t-transparent border-solid rounded-full"></div>
    </div>
  );
};

export default Loader;
