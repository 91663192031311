// src/pages/Instances.jsx
import React, { useMemo, useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchInstances, createInstance, deleteInstanceBulk, updateInstanceBulkAction } from '../../api/instances';
import Loader from '../../components/common/Loader';
import InstancesTable from '../../components/instance/InstancesTable';
import InstanceDetailsPanel from '../../components/instance/InstanceDetailsPanel';
import { GlobalStateContext } from '../../context/GlobalState';
import 'tailwindcss/tailwind.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIpAddresses } from '../../util/networks';
import RealTimeInstanceStatus from '../../components/instance/RealTimeInstanceStatus';

const Instances = () => {
  const { state } = useContext(GlobalStateContext);
  const project = 'internal'; // Specify the project to use
  const queryClient = useQueryClient();
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [processingInstances, setProcessingInstances] = useState([]);

  const { data: instances = [], isLoading, error } = useQuery({
    queryKey: ['instances', project],
    queryFn: () => fetchInstances(project),
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  const createMutation = useMutation({
    mutationFn: (newInstance) => createInstance(newInstance, project),
    onSuccess: () => {
      queryClient.invalidateQueries(['instances', project]);
      toast.success('Instance created successfully!');
    },
  });

  const deleteBulkMutation = useMutation({
    mutationFn: (instances) => deleteInstanceBulk(instances, project),
    onSuccess: () => {
      queryClient.invalidateQueries(['instances', project]);
      setProcessingInstances([]);
      toast.success('Instances deleted successfully!');
    },
  });

  const bulkActionMutation = useMutation({
    mutationFn: (actions) => updateInstanceBulkAction(actions, false, project),
    onSuccess: () => {
      queryClient.invalidateQueries(['instances', project]);
      setProcessingInstances([]);
      toast.success('Bulk action performed successfully!');
    },
  });

  const handleCreateInstance = () => {
    const instanceData = { name: 'New Instance' }; // Customize this as needed
    createMutation.mutate(instanceData);
  };

  const handleBulkDelete = (selectedInstances) => {
    setProcessingInstances(selectedInstances.map(instance => instance.name));
    deleteBulkMutation.mutate(selectedInstances);
  };

  const handleBulkAction = (action, selectedInstances) => {
    setProcessingInstances(selectedInstances.map(instance => instance.name));
    const actions = selectedInstances.map((instance) => ({
      name: instance.name,
      project,
      action,
    }));
    bulkActionMutation.mutate(actions);
  };

  const handleRowClick = (instance) => {
    setSelectedInstance(instance);
  };

  const handleClosePanel = () => {
    setSelectedInstance(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => <span className="text-green-700">{value}</span>,
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'IPv4',
        accessor: (row) => getIpAddresses(row, 'inet').map(ip => ip.address).join(', '),
      },
      {
        Header: 'IPv6',
        accessor: (row) => getIpAddresses(row, 'inet6').map(ip => ip.address).join(', '),
      },
      {
        id: 'status',
        Header: 'Status',
        Cell: ({ row }) => (
          <RealTimeInstanceStatus
            instanceName={row.original.name}
            project={row.original.project}
            isProcessing={processingInstances.includes(row.original.name)}
          />
        ),
      },
    ],
    [processingInstances]
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500">
        <p>Error fetching instances: {error.message}</p>
        <button onClick={() => queryClient.invalidateQueries(['instances', project])} className="bg-red-500 text-white px-4 py-2 rounded mt-4 hover:bg-red-700 transition duration-300">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="flex">
      <div className="flex-1 px-4 py-6">
        <h1 className="text-3xl font-bold mb-4">Instances</h1>
        <div className="flex mb-4 items-center">
          <button
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
            onClick={handleCreateInstance}
          >
            Create Instance
          </button>
        </div>
        <InstancesTable
          columns={columns}
          data={instances}
          handleBulkAction={handleBulkAction}
          handleBulkDelete={handleBulkDelete}
          handleRowClick={handleRowClick}
        />
      </div>
      {selectedInstance && (
        <InstanceDetailsPanel instance={selectedInstance} project={project} onClose={handleClosePanel} />
      )}
    </div>
  );
};

export default Instances;
