import React from 'react';

const Images = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Images</h1>
      <p>This is the images section of the dashboard.</p>
    </div>
  );
};

export default Images;
