import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import { menuItems } from '../../util/menuItems';
import { AuthContext } from '../../context/AuthContext';

const NavbarLinks = () => {
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const servicesMenuRef = useRef(null);
  const { authenticated } = useContext(AuthContext);

  const toggleServicesMenu = () => {
    setIsServicesMenuOpen(!isServicesMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesMenuRef.current && !servicesMenuRef.current.contains(event.target)) {
        setIsServicesMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="flex flex-col md:flex-row md:space-x-8 text-lg font-semibold" aria-label="Primary Navigation">
      {menuItems.map((item) => (
        item.subItems ? (
          <div key={item.name} className="relative" ref={servicesMenuRef}>
            <button
              onClick={toggleServicesMenu}
              className="flex items-center space-x-2 hover:text-gray-200 transition-colors duration-300"
              aria-haspopup="true"
              aria-expanded={isServicesMenuOpen ? 'true' : 'false'}
              aria-controls="services-menu"
            >
              <span>{item.name}</span>
              <FaChevronDown />
            </button>
            {isServicesMenuOpen && (
              <div id="services-menu" className="absolute bg-white text-gray-800 rounded-lg shadow-lg mt-2 w-48 z-10">
                {item.subItems.map((subItem) => (
                  <Link
                    to={subItem.path}
                    key={subItem.name}
                    className="block px-4 py-2 hover:bg-gray-100 flex items-center space-x-2"
                  >
                    <subItem.icon className="text-green-600" />
                    <span className="text-gray-700">{subItem.name}</span>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ) : (
          (item.name !== 'Dashboard' || authenticated) && (
            <Link key={item.name} to={item.path} className="hover:text-gray-200 transition-colors duration-300">
              {item.name}
            </Link>
          )
        )
      ))}
    </nav>
  );
};

export default NavbarLinks;
