import { handleResponse, handleTextResponse } from "../util/helpers";
import config from "../config";

const { protocol, host, port, token } = config.lxdServer;
const baseURL = `${protocol}://${host}:${port}`;

const useAuthHeaders = () => {
  return {
    'Authorization': `Bearer ${token}`,
  };
};

export const fetchSettings = () => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0`, { headers })
      .then(handleResponse)
      .then(data => resolve(data.metadata))
      .catch(reject);
  });
};

export const updateSettings = (config) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0`, {
      method: "PATCH",
      headers,
      body: JSON.stringify({ config }),
    })
      .then(handleResponse)
      .then(resolve)
      .catch(reject);
  });
};

export const fetchResources = () => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/resources`, { headers })
      .then(handleResponse)
      .then(data => resolve(data.metadata))
      .catch(reject);
  });
};

export const fetchConfigOptions = (hasMetadataConfiguration) => {
  const headers = useAuthHeaders();
  if (!hasMetadataConfiguration) {
    return new Promise((resolve) => resolve(null));
  }

  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/metadata/configuration`, { headers })
      .then(handleResponse)
      .then(data => resolve(data.metadata))
      .catch(reject);
  });
};

export const fetchDocObjects = (hasDocumentationObject) => {
  const headers = useAuthHeaders();
  if (!hasDocumentationObject) {
    return new Promise((resolve) => resolve([]));
  }

  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/documentation/objects.inv.txt`, { headers })
      .then(handleTextResponse)
      .then(data => resolve(data.split("\n")))
      .catch(reject);
  });
};
