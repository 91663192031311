import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const Loading = () => {
  const loaderRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      loaderRef.current,
      { rotation: 0 },
      { rotation: 360, repeat: -1, ease: 'linear'}
    );
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div ref={loaderRef} className="w-16 h-16 border-4 border-green-500 border-t-transparent border-solid rounded-full"></div>
    </div>
  );
};

export default Loading;
