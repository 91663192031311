import React, { useEffect, useState } from 'react';
import { fetchSettings, fetchResources, fetchConfigOptions } from '../../api/server';

const Servers = () => {
  const [settings, setSettings] = useState(null);
  const [resources, setResources] = useState(null);
  const [configOptions, setConfigOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const settingsData = await fetchSettings();
        setSettings(settingsData);

        const resourcesData = await fetchResources();
        setResources(resourcesData);

        const configOptionsData = await fetchConfigOptions(true);
        setConfigOptions(configOptionsData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Server Overview</h1>
      {settings && (
        <div className="mb-8">
          <h2 className="text-xl font-bold">Settings</h2>
          <pre>{JSON.stringify(settings, null, 2)}</pre>
        </div>
      )}
      {resources && (
        <div className="mb-8">
          <h2 className="text-xl font-bold">Resources</h2>
          <pre>{JSON.stringify(resources, null, 2)}</pre>
        </div>
      )}
      {configOptions && (
        <div className="mb-8">
          <h2 className="text-xl font-bold">Config Options</h2>
          <pre>{JSON.stringify(configOptions, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Servers;
