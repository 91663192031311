import React from 'react';
import { getIpAddresses } from '../../util/networks';

const InstanceIps = ({ instance, family }) => {
  // console.log('Instance state:', instance.state); // Debug log
  const addresses = getIpAddresses(instance, family);
  // console.log('IP addresses:', addresses); // Debug log

  return addresses.length ? (
    <ul className="list-disc list-inside">
      {addresses.map((item) => (
        <li key={item.address} className="truncate" title={`IP ${item.address} (${item.iface})`}>
          {item.address} 
          {/* ({item.iface}) */}
        </li>
      ))}
    </ul>
  ) : (
    <span></span>
  );
};

export default InstanceIps;
