import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FaCloud, FaServer, FaUsers, FaDatabase, FaTools, FaCogs, FaRobot, FaBrain, FaMagic } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

const ServicesPage = () => {
  const servicesRef = useRef(null);

  useEffect(() => {
    gsap.from(servicesRef.current.children, {
      scrollTrigger: {
        trigger: servicesRef.current,
        start: "top center",
        end: "bottom center",
        toggleActions: "play none none reverse",
      },
      duration: 1,
      opacity: 0,
      y: 50,
      stagger: 0.2,
    });
  }, []);

  return (
    <div className="container mx-auto my-8 p-4 bg-gray-50 dark:bg-stone-950 text-gray-900 dark:text-gray-100 rounded-lg shadow-md pt-16">
      <h2 className="text-3xl font-bold mb-6 text-center text-green-700 dark:text-green-400">Our Services</h2>
      <div ref={servicesRef} className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaCloud className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Infrastructure as a Service (IaaS)</h4>
          <p className="text-gray-700 dark:text-gray-300">Scalable cloud resources to meet your business needs.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaServer className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Platform as a Service (PaaS)</h4>
          <p className="text-gray-700 dark:text-gray-300">Develop and deploy applications effortlessly with our platform solutions.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaRobot className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">GPT API</h4>
          <p className="text-gray-700 dark:text-gray-300">Access advanced language models for your applications.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaBrain className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Generative AI Models</h4>
          <p className="text-gray-700 dark:text-gray-300">Leverage cutting-edge AI models for creative and analytical tasks.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaMagic className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Custom AI Solutions</h4>
          <p className="text-gray-700 dark:text-gray-300">Tailored AI solutions to meet specific business requirements.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaDatabase className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Database Management</h4>
          <p className="text-gray-700 dark:text-gray-300">Secure and scalable database solutions for your data needs.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaTools className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">DevOps Services</h4>
          <p className="text-gray-700 dark:text-gray-300">Streamline your development process with our DevOps expertise.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaCogs className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Managed Services</h4>
          <p className="text-gray-700 dark:text-gray-300">Comprehensive managed services to keep your infrastructure running smoothly.</p>
        </div>
        <div className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaUsers className="text-6xl text-green-500 dark:text-white mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">24/7 Support</h4>
          <p className="text-gray-700 dark:text-gray-300">Our team is here to help you at any time, day or night.</p>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
