import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FaCloud, FaServer, FaRobot, FaShieldAlt, FaRocket, FaDollarSign } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {
  const refs = useRef({
    hero: null,
    benefits: null,
    services: null,
    joinUs: null,
    cta: null
  });
  const navigate = useNavigate();

  useEffect(() => {
    const { hero, benefits, services, joinUs, cta } = refs.current;

    gsap.from(hero, { duration: 1, opacity: 0, y: 30, ease: 'power3.out' });

    const sections = [benefits, services, joinUs];
    sections.forEach(section => {
      gsap.fromTo(section.children, { y: 100, opacity: 0 }, {
        duration: 0.8,
        opacity: 1,
        y: 0,
        stagger: 0.3,
        scrollTrigger: {
          trigger: section,
          start: "top bottom-=100",
          end: "bottom top",
          toggleActions: "restart none none reset",
          markers: false
        },
        // ease: 'elastic.out(1, 0.5)'
      });
    });

    gsap.from(cta, {
      duration: 1.2,
      opacity: 0,
      scale: 0.5,
      scrollTrigger: {
        trigger: cta,
        start: "top bottom-=200",
        toggleActions: "restart none none reset"
      },
      // ease: 'back.out(1.7)'
    });
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="relative bg-gray-50 dark:bg-stone-950 text-gray-900 dark:text-gray-100">
      <div className="relative z-10 pt-20">
        <section ref={el => refs.current.hero = el} className="hero bg-gradient-to-r from-green-600 to-green-800 dark:from-green-700 dark:to-green-900 text-white p-8 rounded-lg shadow-md text-center mb-8">
          <h2 className="text-4xl font-bold mb-4">Welcome to DadiSpace</h2>
          <p className="text-lg mb-6">Your ultimate cloud provider and web hosting solution</p>
          <button onClick={() => handleNavigation('/signup')} className="bg-white text-green-600 font-bold py-2 px-4 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-transform duration-300 transform hover:scale-105">
            Get Started
          </button>
        </section>

        <section ref={el => refs.current.benefits = el} className="container mx-auto my-8">
          <h3 className="text-3xl font-bold mb-6 text-center">Why DadiSpace?</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { icon: FaDollarSign, title: "Affordable Pricing", desc: "Get the best value for your money with our competitive pricing." },
              { icon: FaShieldAlt, title: "Top-Notch Security", desc: "Your data is safe with our industry-leading security measures." },
              { icon: FaRocket, title: "Fast Performance", desc: "Experience blazing fast speeds and reliable uptime." }
            ].map(({ icon: Icon, title, desc }, index) => (
              <div key={index} className="benefit bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
                <Icon className="text-6xl text-green-500 dark:text-white mb-4" />
                <h4 className="text-2xl font-bold mb-2">{title}</h4>
                <p className="text-gray-700 dark:text-gray-300">{desc}</p>
              </div>
            ))}
          </div>
        </section>

        <section ref={el => refs.current.services = el} className="container mx-auto my-8">
          <h3 className="text-3xl font-bold mb-6 text-center">Our Services</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { icon: FaCloud, title: "Infrastructure as a Service (IaaS)", desc: "Scalable cloud resources to meet your business needs." },
              { icon: FaServer, title: "Platform as a Service (PaaS)", desc: "Develop and deploy applications effortlessly with our platform solutions." },
              { icon: FaRobot, title: "GPT API", desc: "Access advanced language models for your applications." }
            ].map(({ icon: Icon, title, desc }, index) => (
              <div key={index} className="service bg-white dark:bg-green-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
                <Icon className="text-6xl text-green-500 dark:text-white mb-4" />
                <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">{title}</h4>
                <p className="text-gray-700 dark:text-gray-300">{desc}</p>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <button onClick={() => handleNavigation('/services')} className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 transition-transform duration-300 transform hover:scale-105">
              View All Services
            </button>
          </div>
        </section>

        <section ref={el => refs.current.joinUs = el} className="bg-gradient-to-r from-green-600 to-green-700 dark:from-green-700 dark:to-green-800 text-white p-8 rounded-lg shadow-md text-center my-8">
          <h3 className="text-3xl font-bold mb-4">Join DadiSpace</h3>
          <p className="text-lg mb-6">Become a part of the fastest growing cloud provider community. Enjoy our top-notch services at unbeatable prices.</p>
          <div className="flex justify-center space-x-4">
            <button onClick={() => handleNavigation('/signup')} className="bg-white text-green-600 font-bold py-2 px-4 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-transform duration-300 transform hover:scale-105">
              Sign Up Now
            </button>
            <button className="bg-transparent border border-white text-white font-bold py-2 px-4 rounded-lg hover:bg-white hover:text-green-600 transition-transform duration-300 transform hover:scale-105">
              Learn More
            </button>
          </div>
        </section>

        <section ref={el => refs.current.cta = el} className="cta bg-gradient-to-r from-purple-600 to-indigo-700 dark:from-purple-700 dark:to-indigo-800 text-white p-8 rounded-lg shadow-md text-center my-8">
          <h3 className="text-3xl font-bold mb-4">Ready to get started?</h3>
          <p className="text-lg mb-6">Join DadiSpace today and take your business to the next level.</p>
          <button onClick={() => handleNavigation('/signup')} className="bg-white text-green-600 font-bold py-2 px-4 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-transform duration-300 transform hover:scale-105">
            Sign Up Now
          </button>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
