import React from 'react';
import { FaTimes, FaExpand, FaCompress } from 'react-icons/fa';
import TABS from '../../util/instancePanelTabs';

const PanelHeader = ({ isExpanded, onToggleExpand, onClose, activeTab, setActiveTab }) => {
  return (
    <div className="panel-header absolute top-0 left-0 right-0 flex justify-between items-center px-4 py-2 bg-green-900 dark:bg-green-700 text-white border-b border-gray-300 dark:border-gray-700 z-10 cursor-move">
      <div className="flex">
        {TABS.map((tab) => (
          <button
            key={tab.name}
            className={`flex items-center px-4 py-2 text-sm font-medium transition-colors duration-300 ${activeTab === tab.name ? 'text-white' : 'text-gray-200 hover:text-white'}`}
            onClick={() => setActiveTab(tab.name)}
          >
            <tab.icon className="mr-2" />
            {tab.name}
          </button>
        ))}
      </div>
      <div className="flex items-center">
        <button onClick={onToggleExpand} className="text-gray-200 hover:text-white mx-2">
          {isExpanded ? <FaCompress /> : <FaExpand />}
        </button>
        <button onClick={onClose} className="text-gray-200 hover:text-white">
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default PanelHeader;
