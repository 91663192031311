import { handleResponse, handleEtagResponse, handleTextResponse } from '../util/helpers';
import config from '../config';

const { protocol, host, port, token } = config.lxdServer;
const baseURL = `${protocol}://${host}:${port}`;

const useAuthHeaders = () => {
  return {
    'Authorization': `Bearer ${token}`,
  };
};

export const fetchInstanceStatus = async ({ instanceName, project }) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${instanceName}?project=${project}`, { headers })
      .then(response => response.json())
      .then(data => resolve(data.metadata.status))
      .catch(reject);
  });
};


export const fetchInstance = (name, project, recursion = 2) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}?project=${project}&recursion=${recursion}`, { headers })
      .then(handleEtagResponse)
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const fetchInstances = (project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances?project=${project}&recursion=2`, { headers })
      .then(handleResponse)
      .then((data) => resolve(data.metadata))
      .catch(reject);
  });
};

export const createInstance = (body, project, target) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances?project=${project}&target=${target ?? ""}`, {
      method: "POST",
      body: body,
      headers: { ...headers },
    })
      .then(handleResponse)
      .then(resolve)
      .catch(reject);
  });
};

export const updateInstance = (instance, project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${instance.name}?project=${project}`, {
      method: "PUT",
      body: JSON.stringify(instance),
      headers: {
        ...headers,
        "If-Match": instance.etag ?? "invalid-etag",
      },
    })
      .then(handleResponse)
      .then(resolve)
      .catch(reject);
  });
};

export const renameInstance = (oldName, newName, project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${oldName}?project=${project}`, {
      method: "POST",
      body: JSON.stringify({ name: newName }),
      headers,
    })
      .then(handleResponse)
      .then(resolve)
      .catch(reject);
  });
};

export const migrateInstance = (name, project, target) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}?project=${project}&target=${target}`, {
      method: "POST",
      body: JSON.stringify({ migration: true }),
      headers,
    })
      .then(handleResponse)
      .then(resolve)
      .catch(reject);
  });
};

export const startInstance = (instance) => {
  return putInstanceAction(instance.name, instance.project, "start");
};

export const stopInstance = (instance, isForce) => {
  return putInstanceAction(instance.name, instance.project, "stop", isForce);
};

export const freezeInstance = (instance) => {
  return putInstanceAction(instance.name, instance.project, "freeze");
};

export const unfreezeInstance = (instance) => {
  return putInstanceAction(instance.name, instance.project, "unfreeze");
};

export const restartInstance = (instance, isForce) => {
  return putInstanceAction(instance.name, instance.project, "restart", isForce);
};

const putInstanceAction = (instance, project, action, isForce) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${instance}/state?project=${project}`, {
      method: "PUT",
      body: JSON.stringify({ action, force: isForce }),
      headers,
    })
      .then(handleResponse)
      .then(resolve)
      .catch(reject);
  });
};

export const updateInstanceBulkAction = (actions, isForce, eventQueue) => {
  const headers = useAuthHeaders();
  const results = [];
  return new Promise((resolve) => {
    void Promise.allSettled(
      actions.map(async ({ name, project, action }) => {
        return await putInstanceAction(name, project, action, isForce)
          .then((operation) => {
            eventQueue.set(
              operation.metadata.id,
              () => pushSuccess(results),
              (msg) => pushFailure(results, msg),
              () => continueOrFinish(results, actions.length, resolve),
            );
          })
          .catch((e) => {
            pushFailure(results, e instanceof Error ? e.message : "");
            continueOrFinish(results, actions.length, resolve);
          });
      }),
    );
  });
};

export const deleteInstance = (instance) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${instance.name}?project=${instance.project}`, {
      method: "DELETE",
      headers,
    })
      .then(handleResponse)
      .then(resolve)
      .catch(reject);
  });
};

export const deleteInstanceBulk = (instances, eventQueue) => {
  const headers = useAuthHeaders();
  const results = [];
  return new Promise((resolve) => {
    void Promise.allSettled(
      instances.map(async (instance) => {
        return await deleteInstance(instance)
          .then((operation) => {
            eventQueue.set(
              operation.metadata.id,
              () => pushSuccess(results),
              (msg) => pushFailure(results, msg),
              () => continueOrFinish(results, instances.length, resolve),
            );
          })
          .catch((e) => {
            pushFailure(results, e instanceof Error ? e.message : "");
            continueOrFinish(results, instances.length, resolve);
          });
      }),
    );
  });
};

export const connectInstanceExec = (name, project, payload) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}/exec?project=${project}&wait=10`, {
      method: "POST",
      body: JSON.stringify({
        command: [payload.command],
        "wait-for-websocket": true,
        environment: payload.environment.reduce(
          (a, v) => ({ ...a, [v.key]: v.value }),
          {},
        ),
        interactive: true,
        group: payload.group,
        user: payload.user,
      }),
      headers,
    })
      .then(handleResponse)
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const connectInstanceVga = (name, project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}/console?project=${project}&wait=10`, {
      method: "POST",
      body: JSON.stringify({
        type: "vga",
        width: 0,
        height: 0,
      }),
      headers,
    })
      .then(handleResponse)
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const connectInstanceConsole = (name, project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}/console?project=${project}&wait=10`, {
      method: "POST",
      body: JSON.stringify({
        "wait-for-websocket": true,
        type: "console",
      }),
      headers,
    })
      .then(handleResponse)
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const fetchInstanceConsoleBuffer = (name, project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}/console?project=${project}`, {
      method: "GET",
      headers,
    })
      .then(handleTextResponse)
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const fetchInstanceLogs = (name, project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}/logs?project=${project}`, {
      method: "GET",
      headers,
    })
      .then(handleResponse)
      .then((data) => resolve(data.metadata))
      .catch(reject);
  });
};

export const fetchInstanceLogFile = (name, project, file) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/instances/${name}/logs/${file}?project=${project}`, {
      method: "GET",
      headers,
    })
      .then(handleTextResponse)
      .then((data) => resolve(data))
      .catch(reject);
  });
};
