import React from 'react';
import { Outlet } from 'react-router-dom';

const DashboardPage = () => {
  return (
    <div className="flex min-h-screen bg-gray-50 dark:bg-stone-950 text-gray-900 dark:text-gray-100">
      <main className="flex-grow p-6 bg-gray-100 dark:bg-gray-900">
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardPage;
