import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import ServicesPage from '../pages/ServicesPage';
import ContactPage from '../pages/ContactPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import DashboardPage from '../pages/DashboardPage';
import Overview from '../pages/dashboard/Overview';
import Instances from '../pages/dashboard/Instances';
import Images from '../pages/dashboard/Images';
import Networks from '../pages/dashboard/Networks';
import Storage from '../pages/dashboard/Storage';
import Servers from '../pages/dashboard/Servers';
import Security from '../pages/dashboard/Security';
import Settings from '../pages/dashboard/Settings';
import ProtectedRoute from '../components/layout/ProtectedRoute';
import { AuthContext } from '../context/AuthContext';

const AppRoutes = () => {
  const { authenticated } = React.useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/login" element={authenticated ? <Navigate to="/dashboard/overview" replace/> : (<></>)} />
      <Route path="/signup" element={authenticated ? <Navigate to="/dashboard/overview" /> : (<></>)} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/dashboard/*" element={authenticated ? <ProtectedRoute><DashboardPage /></ProtectedRoute> : <Navigate to="/" replace/>}>
        <Route path="overview" element={<Overview />} />
        <Route path="instances" element={<Instances />} />
        <Route path="images" element={<Images />} />
        <Route path="networks" element={<Networks />} />
        <Route path="storage" element={<Storage />} />
        <Route path="servers" element={<Servers />} />
        <Route path="security" element={<Security />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
