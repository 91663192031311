import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  Filler // Import the Filler plugin
} from 'chart.js';
import 'chartjs-adapter-moment';
import { fetchMetrics } from '../../api/metrics';
import { queryKeys } from '../../util/queryKeys';
import Loader from './../common/Loader';


ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  Filler // Register the Filler plugin
);

const PanelMonitoring = ({ instance, onFailure, project }) => {
  const { data: metrics = [], error, isLoading } = useQuery({
    queryKey: [queryKeys.metrics, instance.name, project],
    queryFn: () => fetchMetrics(instance.name, project),
    refetchInterval: 15000, // 15 seconds
  });

  const [cpuData, setCpuData] = useState([]);
  const [memoryData, setMemoryData] = useState([]);
  const [networkData, setNetworkData] = useState([]);

  useEffect(() => {
    console.log(metrics)
    if (metrics.length > 0) {
      console.log('Fetched Metrics:', metrics); // Debugging log

      const cpuMetrics = metrics
        .filter(group => group.name === 'incus_cpu_seconds_total')
        .map(point => ({ x: point.metrics[0].labels.cpu, y: parseFloat(point.metrics[0].value) }));

      const memoryMetrics = metrics
        .filter(group => group.name === 'incus_memory_Active_bytes')
        .map(point => ({ x: point.metrics[0].timestamp_ms, y: parseFloat(point.metrics[0].value) }));

      const networkMetrics = metrics
        .filter(group => group.name === 'incus_network_receive_bytes_total')
        .map(point => ({ x: point.metrics[0].timestamp_ms, y: parseFloat(point.metrics[0].value) }));

      console.log('CPU Metrics:', cpuMetrics); // Debugging log
      console.log('Memory Metrics:', memoryMetrics); // Debugging log
      console.log('Network Metrics:', networkMetrics); // Debugging log

      setCpuData(cpuMetrics || []);
      setMemoryData(memoryMetrics || []);
      setNetworkData(networkMetrics || []);
    }
  }, [metrics]);

  if (error) {
    // onFailure('Loading metrics failed', error);
    return (<>Loading metrics failed</>)
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#6b7280',
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: '#333',
        bodyColor: '#333',
        borderColor: '#ddd',
        borderWidth: 1,
      },
      title: {
        display: true,
        text: 'Usage Over Time',
        font: {
          size: 18,
          weight: 'bold',
        },
        color: '#333',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'second',
        },
        grid: {
          display: true,
          borderColor: '#e5e7eb',
          color: '#f1f1f1',
        },
        ticks: {
          color: '#6b7280',
        },
      },
      y: {
        grid: {
          display: true,
          borderColor: '#e5e7eb',
          color: '#f1f1f1',
        },
        ticks: {
          color: '#6b7280',
        },
      },
    },
    elements: {
      point: {
        radius: 2,
      },
      line: {
        borderWidth: 2,
        tension: 0.4,
      },
    },
  };

  const generateChartData = (data, label, color) => ({
    datasets: [
      {
        label,
        data,
        fill: true,
        borderColor: color,
        backgroundColor: `${color}33`,
        tension: 0.4,
      },
    ],
  });

  return (
    <div className="p-4">
      {isLoading ? (
        <Loader text="Loading metrics..." />
      ) : (
        <>
          <h3 className="text-lg font-semibold mb-4">CPU Usage</h3>
          <div className="bg-white rounded-lg shadow p-4 mb-6">
            <Line data={generateChartData(cpuData, 'CPU Usage', 'rgba(75, 192, 192, 1)')} options={chartOptions} />
          </div>
          
          <h3 className="text-lg font-semibold mb-4">Memory Usage</h3>
          <div className="bg-white rounded-lg shadow p-4 mb-6">
            <Line data={generateChartData(memoryData, 'Memory Usage', 'rgba(153, 102, 255, 1)')} options={chartOptions} />
          </div>
          
          <h3 className="text-lg font-semibold mb-4">Network Usage</h3>
          <div className="bg-white rounded-lg shadow p-4">
            <Line data={generateChartData(networkData, 'Network Usage', 'rgba(255, 159, 64, 1)')} options={chartOptions} />
          </div>
        </>
      )}
    </div>
  );
};

export default PanelMonitoring;
