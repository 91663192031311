import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import NavbarLogo from './NavbarLogo';
import NavbarLinks from './NavbarLinks';
import NavbarSearch from './NavbarSearch';
import NavbarProfile from './NavbarProfile';
import ThemeSwitcher from './ThemeSwitcher';
import { useState } from 'react';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-green-600 to-green-900 dark:from-green-800 dark:to-green-700 text-white p-4 shadow-md fixed w-full z-20 top-0">
      <div className="container mx-auto flex justify-between items-center">
        <NavbarLogo />
        <div className="hidden md:flex items-center space-x-4 flex-grow justify-center md:justify-end">
          <NavbarSearch />
          <NavbarLinks />
          <ThemeSwitcher />
          <NavbarProfile />
        </div>
        <button
          onClick={toggleMobileMenu}
          className="md:hidden text-white focus:outline-none"
          aria-label="Toggle mobile menu"
        >
          {isMobileMenuOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-30 flex justify-end">
          <div className="bg-green-600 dark:bg-green-800 text-white w-64 p-4 space-y-4 shadow-md transition duration-300 ease-in-out">
            <button
              onClick={toggleMobileMenu}
              className="text-white focus:outline-none"
              aria-label="Close mobile menu"
            >
              <FaTimes className="text-2xl" />
            </button>
            <NavbarLinks />
            <NavbarSearch />
            <ThemeSwitcher />
            <NavbarProfile />
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
