import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

const ContactPage = () => {
  const contactInfoRef = useRef(null);
  const contactFormRef = useRef(null);

  useEffect(() => {
    gsap.from(contactInfoRef.current.children, {
      scrollTrigger: {
        trigger: contactInfoRef.current,
        start: "top center",
        end: "bottom center",
        toggleActions: "play none none reverse",
      },
      duration: 1,
      opacity: 0,
      y: -50,
      stagger: 0.2,
    });

    gsap.from(contactFormRef.current, {
      scrollTrigger: {
        trigger: contactFormRef.current,
        start: "top center",
        end: "bottom center",
        toggleActions: "play none none reverse",
      },
      duration: 1,
      opacity: 0,
      x: 50,
    });
  }, []);

  return (
    <div className="container mx-auto my-8 p-4 py-10 bg-gray-50 dark:bg-stone-950 text-gray-900 dark:text-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-center">Contact Us</h2>
      <div ref={contactInfoRef} className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
        <div className="contact-info bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaPhone className="text-6xl text-green-500 mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Phone</h4>
          <p className="text-gray-700 dark:text-gray-300">+1 440 440 220</p>
        </div>
        <div className="contact-info bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaEnvelope className="text-6xl text-green-500 mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Email</h4>
          <p className="text-gray-700 dark:text-gray-300">support@dadispace.com</p>
        </div>
        <div className="contact-info bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2">
          <FaMapMarkerAlt className="text-6xl text-green-500 mb-4 mx-auto" />
          <h4 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-100">Address</h4>
          <p className="text-gray-700 dark:text-gray-300">23 Cloud St, Paris, 75001</p>
        </div>
      </div>
      <div ref={contactFormRef} className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md">
        <h4 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-100">Send Us a Message</h4>
        <form className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="name" className="text-gray-700 dark:text-gray-300 font-semibold">Name</label>
            <input type="text" id="name" className="p-2 rounded border border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400" placeholder="Your Name" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="email" className="text-gray-700 dark:text-gray-300 font-semibold">Email</label>
            <input type="email" id="email" className="p-2 rounded border border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400" placeholder="Your Email" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="message" className="text-gray-700 dark:text-gray-300 font-semibold">Message</label>
            <textarea id="message" rows="5" className="p-2 rounded border border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400" placeholder="Your Message"></textarea>
          </div>
          <div className="text-center">
            <button type="submit" className="bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 dark:hover:bg-green-500 transition-colors duration-300">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
