import React, { useEffect, useRef } from 'react';
import { FaCog, FaUserCog, FaBell, FaShieldAlt } from 'react-icons/fa';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Settings = () => {
  const settingsRef = useRef(null);

  useEffect(() => {
    gsap.from(settingsRef.current, {
      duration: 1,
      opacity: 0,
      y: 50,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: settingsRef.current,
        start: 'top center',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
      },
    });
  }, []);

  return (
    <div ref={settingsRef} className="container mx-auto p-8 bg-white shadow-md rounded-lg">
      <h2 className="text-3xl font-bold text-center mb-6">Account Settings</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="settings-item bg-gray-50 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
          <FaUserCog className="text-6xl text-green-500 mb-4" />
          <h4 className="text-2xl font-bold mb-2">Profile Settings</h4>
          <p className="text-gray-700">Update your personal information and change your profile settings.</p>
        </div>
        <div className="settings-item bg-gray-50 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
          <FaBell className="text-6xl text-green-500 mb-4" />
          <h4 className="text-2xl font-bold mb-2">Notification Settings</h4>
          <p className="text-gray-700">Manage your notification preferences and settings.</p>
        </div>
        <div className="settings-item bg-gray-50 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 cursor-pointer">
          <FaShieldAlt className="text-6xl text-green-500 mb-4" />
          <h4 className="text-2xl font-bold mb-2">Security Settings</h4>
          <p className="text-gray-700">Adjust your security settings and manage your account security.</p>
        </div>
      </div>
    </div>
  );
};

export default Settings;
