// src/components/instance/ActionButton.jsx
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ActionButton = ({ icon, label, onClick }) => (
  <button
    onClick={onClick}
    className="text-gray-500 hover:text-gray-700 transition duration-200 p-2"
    aria-label={label}
    data-tooltip-id={label}
  >
    {icon}
    <ReactTooltip id={label} place="top" effect="solid">
      {label}
    </ReactTooltip>
  </button>
);

export default ActionButton;
