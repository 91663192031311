// src/util/statusStyles.js

export const statusToColorClasses = (status) => {
    switch (status) {
      case "Running":
        return "bg-green-100 text-green-800";
      case "Stopped":
      case "Error":
        return "bg-red-100 text-red-800";
      case "Starting":
      case "Stopping":
        return "bg-yellow-100 text-yellow-800";
      case "Freezing":
      case "Frozen":
        return "bg-blue-100 text-blue-800";
      case "Restarting":
        return "bg-purple-100 text-purple-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };
  