import { FaHome, FaInfoCircle, FaServicestack, FaCloud, FaServer, FaRobot, FaBrain, FaMagic, FaDatabase, FaEnvelope, FaTachometerAlt } from 'react-icons/fa';

export const menuItems = [
  { name: 'Home', path: '/', icon: FaHome },
  { name: 'About', path: '/about', icon: FaInfoCircle },
  {
    name: 'Services',
    path: 'services',
    icon: FaServicestack,
    subItems: [
      { name: 'IaaS', path: '/services#iaas', icon: FaCloud },
      { name: 'PaaS', path: '/services#paas', icon: FaServer },
      { name: 'GPT-3 API', path: '/services#gpt3', icon: FaRobot },
      { name: 'Generative AI Models', path: '/services#ai-models', icon: FaBrain },
      { name: 'Custom AI Solutions', path: '/services#custom-ai', icon: FaMagic },
      { name: 'Database Management', path: '/services#database', icon: FaDatabase },
    ],
  },
  { name: 'Contact', path: '/contact', icon: FaEnvelope },
  { name: 'Dashboard', path: '/dashboard', icon: FaTachometerAlt },
];
