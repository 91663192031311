import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import NavbarLogo from '../components/header/NavbarLogo';
import { backgroundLight } from '../util';

gsap.registerPlugin(ScrollTrigger);

const ForgotPasswordPage = () => {
  const formRef = useRef(null);

  useEffect(() => {
    gsap.from(formRef.current, {
      duration: 1,
      opacity: 0,
      y: 50,
      ease: 'power3.out',
    });
  }, []);

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${backgroundLight})` }}>
      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
      <div className="absolute top-4 left-4 z-10">
        <NavbarLogo />
      </div>
      <div ref={formRef} className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md z-10">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Reset Your Password</h2>
        <p className="text-center text-gray-600 mb-4">Enter your email address below and we will send you instructions to reset your password.</p>
        <form className="space-y-6">
          <div className="flex flex-col">
            <label htmlFor="email" className="text-gray-600 font-medium">Email</label>
            <input
              type="email"
              id="email"
              className="p-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Your Email"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 transition-colors duration-300"
            >
              Send Instructions
            </button>
          </div>
          <div className="text-center">
            <p className="text-gray-600">Remember your password? <Link to="/login" className="text-green-600 hover:text-green-800">Login</Link></p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
