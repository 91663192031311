import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FaBullseye, FaFlag, FaUsers, FaLaptopCode, FaChartLine } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

const AboutPage = () => {
  const missionRef = useRef(null);
  const visionRef = useRef(null);

  useEffect(() => {
    gsap.from(missionRef.current, {
      scrollTrigger: {
        trigger: missionRef.current,
        start: "top center",
        end: "bottom center",
        toggleActions: "play none none reverse",
      },
      duration: 1,
      opacity: 0,
      y: 50,
    });

    gsap.from(visionRef.current, {
      scrollTrigger: {
        trigger: visionRef.current,
        start: "top center",
        end: "bottom center",
        toggleActions: "play none none reverse",
      },
      duration: 1,
      opacity: 0,
      y: 50,
    });

  }, []);

  return (
    <div className="container mx-auto my-8 p-4 bg-gray-50 dark:bg-gray-950 text-gray-900 dark:text-gray-100 rounded-lg shadow-md">
      <section ref={missionRef} className="text-center my-8">
        <div className="flex flex-col items-center">
          <FaBullseye className="text-6xl text-green-500 mb-4" />
          <h2 className="text-3xl font-bold mb-4">Our Mission</h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            At DadiSpace, our mission is to provide the most affordable and reliable cloud services to help businesses of all sizes succeed. We are committed to delivering top-notch solutions that empower our customers to innovate and grow.
          </p>
        </div>
      </section>

      <section ref={visionRef} className="text-center my-8">
        <div className="flex flex-col items-center">
          <FaFlag className="text-6xl text-green-500 mb-4" />
          <h2 className="text-3xl font-bold mb-4">Our Vision</h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            Our vision is to become the leading cloud service provider known for exceptional customer service, innovative solutions, and unbeatable prices. We aim to make cloud services accessible to everyone, everywhere.
          </p>
        </div>
      </section>

      <section className="text-center p-8 bg-green-600 dark:bg-green-700 text-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4">Why Choose DadiSpace?</h2>
        <p className="text-lg mb-6">We offer affordable, reliable, and scalable cloud solutions with top-notch security and exceptional customer support.</p>
        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex flex-col items-center space-y-2">
            <FaLaptopCode className="text-4xl" />
            <span className="text-lg">Cutting-edge Technology</span>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <FaChartLine className="text-4xl" />
            <span className="text-lg">Proven Performance</span>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <FaUsers className="text-4xl" />
            <span className="text-lg">Customer Centric</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
