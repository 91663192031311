import React from 'react';
import { FaTwitter, FaFacebook, FaLinkedin, FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-green-800 text-white py-8">
      <div className="container mx-auto px-4 flex flex-wrap justify-between">
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h2 className="text-lg font-bold mb-4">DadiSpace</h2>
          <p className="text-sm">&copy; {new Date().getFullYear()} DadiSpace. All rights reserved.</p>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-lg font-bold mb-4">About Us</h3>
          <p className="text-sm">We are dedicated to providing the best cloud services and web hosting solutions to help your business grow.</p>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Services</h3>
          <ul className="text-sm space-y-2">
            <li><a href="/services#iaas" className="hover:underline">Infrastructure as a Service (IaaS)</a></li>
            <li><a href="/services#paas" className="hover:underline">Platform as a Service (PaaS)</a></li>
            <li><a href="/services#gpt3" className="hover:underline">GPT-3 API</a></li>
            <li><a href="/services#ai-models" className="hover:underline">Generative AI Models</a></li>
            <li><a href="/services#custom-ai" className="hover:underline">Custom AI Solutions</a></li>
            <li><a href="/services#database" className="hover:underline">Database Management</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Quick Links</h3>
          <ul className="text-sm space-y-2">
            <li><a href="/about" className="hover:underline">About Us</a></li>
            <li><a href="/contact" className="hover:underline">Contact Us</a></li>
            <li><a href="/signup" className="hover:underline">Sign Up</a></li>
            <li><a href="/login" className="hover:underline">Login</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/4">
          <h3 className="text-lg font-bold mb-4">Follow Us</h3>
          <div className="flex space-x-4">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitter className="text-xl hover:text-gray-400 transition-colors duration-300" />
            </a>
            <a href="https://facebook.com/dadispace" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebook className="text-xl hover:text-gray-400 transition-colors duration-300" />
            </a>
            <a href="https://www.linkedin.com/company/dadispace/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin className="text-xl hover:text-gray-400 transition-colors duration-300" />
            </a>
            <a href="https://github.com/dadispace" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
              <FaGithub className="text-xl hover:text-gray-400 transition-colors duration-300" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
