import React from 'react';
import InstanceIps from './InstanceIps';
import PanelMonitoring from './PanelMonitoring';
import RealTimeInstanceStatus from './RealTimeInstanceStatus';

const PanelContent = ({ instance, activeTab, project }) => {
  const renderContent = () => {
    switch (activeTab) {
      case 'Details':
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
            <DetailRow label="Name:" value={instance.name} />
            <DetailRow label="Base Image:" value={instance.config["image.description"]} />
            <DetailRow label="Status:">
              <RealTimeInstanceStatus instanceName={instance.name} project={project} />
            </DetailRow>
            <DetailRow label="Description:" value={instance.description} />
            <DetailRow label="Type:" value={instance.type} />
            <DetailRow label="IPv4:">
              <InstanceIps instance={instance} family="inet" />
            </DetailRow>
            <DetailRow label="IPv6:">
              <InstanceIps instance={instance} family="inet6" />
            </DetailRow>
            <DetailRow label="Architecture:" value={instance.architecture} />
            <DetailRow label="Location:" value={instance.location} />
            <DetailRow label="Created:" value={new Date(instance.created_at).toLocaleString()} />
            <DetailRow label="Last Used:" value={new Date(instance.last_used_at).toLocaleString()} />
          </div>
        );
      case 'Monitoring':
        return <PanelMonitoring instance={instance} project={project} />;
      case 'Security':
        return <div className="p-4">Security content goes here.</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

const DetailRow = ({ label, value, children }) => (
  <div className="flex items-center">
    <span className="font-semibold">{label}</span>
    {value ? <span className="ml-2">{value}</span> : children}
  </div>
);

export default PanelContent;
