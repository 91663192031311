import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaStop, FaRedo, FaTrashAlt } from 'react-icons/fa';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import 'tailwindcss/tailwind.css';

const BulkActionDropdown = ({ handleBulkAction, handleBulkDelete, selectedRows }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const openConfirmationModal = (action) => {
    setActionType(action);
    setIsModalOpen(true);
  };

  const handleConfirmAction = () => {
    if (actionType === 'delete') {
      handleBulkDelete(selectedRows);
    } else {
      handleBulkAction(actionType, selectedRows);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="border-green-600 ml-2 text-white px-4 py-2 rounded-lg hover:bg-primary-dark transition-all duration-300"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        Actions
      </button>
      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg z-10 animate-fade-in">
          <button
            onClick={() => openConfirmationModal('start')}
            className="flex items-center w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200"
          >
            <FaPlay className="mr-2" /> Start
          </button>
          <button
            onClick={() => openConfirmationModal('stop')}
            className="flex items-center w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200"
          >
            <FaStop className="mr-2" /> Stop
          </button>
          <button
            onClick={() => openConfirmationModal('restart')}
            className="flex items-center w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200"
          >
            <FaRedo className="mr-2" /> Restart
          </button>
          <button
            onClick={() => openConfirmationModal('delete')}
            className="flex items-center w-full px-4 py-2 text-left text-red-700 dark:text-red-500 hover:bg-red-100 dark:hover:bg-red-700 transition duration-200"
          >
            <FaTrashAlt className="mr-2" /> Delete
          </button>
        </div>
      )}

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} center classNames={{ modal: 'rounded-lg p-6 dark:bg-gray-900 dark:text-gray-100' }}>
        <h2 className="text-xl font-semibold mb-4">Confirm {actionType} Action</h2>
        <p className="mb-4">Are you sure you want to {actionType} the selected instances?</p>
        <div className="flex justify-end">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600 transition-all duration-300"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-all duration-300"
            onClick={handleConfirmAction}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default BulkActionDropdown;
