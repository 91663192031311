// src/components/instance/IndeterminateCheckbox.jsx
import React, { useEffect, useRef } from 'react';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} className="form-checkbox h-4 w-4 text-purple-600 transition duration-150 ease-in-out" />;
});

export default IndeterminateCheckbox;
