export const queryKeys = {
  certificates: "certificates",
  cluster: "cluster",
  configOptions: "configOptions",
  forwards: "forwards",
  groups: "groups",
  images: "images",
  instances: "instances",
  customVolumes: "customVolumes",
  isoVolumes: "isoVolumes",
  logs: "logs",
  members: "members",
  metrics: "metrics",
  networks: "networks",
  operations: "operations",
  profiles: "profiles",
  projects: "projects",
  resources: "resources",
  storage: "storage",
  settings: "settings",
  state: "state",
  volumes: "volumes",
  warnings: "warnings",
  snapshots: "snapshots",
};
