import React, { useState, useRef } from 'react';
import { FaTimes, FaExpand, FaCompress } from 'react-icons/fa';
import { Rnd } from 'react-rnd';
import PanelHeader from './PanelHeader';
import PanelContent from './PanelContent';

const InstanceDetailsPanel = ({ instance, onClose, project }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('Details');
  const startSize = {
    x: window.innerWidth * 0.224,
    y: window.innerHeight * 0.5,
    width: window.innerWidth * 0.7,
    height: window.innerHeight * 0.4,
  };
  const [prevSize, setPrevSize] = useState(startSize);

  const rndRef = useRef();

  if (!instance) return null;

  const toggleExpand = () => {
    if (isExpanded) {
      rndRef.current.updatePosition({ x: startSize.x, y: startSize.y });
      rndRef.current.updateSize({ width: startSize.width, height: startSize.height });
    } else {
      setPrevSize({
        x: rndRef.current.state.x,
        y: rndRef.current.state.y,
        width: rndRef.current.state.width,
        height: rndRef.current.state.height,
      });
      rndRef.current.updatePosition({ x: 0, y: 0 });
      rndRef.current.updateSize({ width: '100%', height: '100%' });
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Rnd
      ref={rndRef}
      default={prevSize}
      minHeight={200}
      minWidth={320}
      bounds="window"
      dragHandleClassName="panel-header"
      enableResizing={{
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setPrevSize({
          width: ref.style.width,
          height: ref.style.height,
          ...position,
        });
      }}
      onDragStop={(e, d) => {
        setPrevSize((prev) => ({ ...prev, x: d.x, y: d.y }));
      }}
      className="z-50 absolute shadow-lg border border-gray-300 dark:border-gray-700 rounded-lg bg-white dark:bg-stone-950 overflow-hidden"
    >
      <div className="relative bg-gradient-to-b from-white to-gray-200 dark:from-gray-800 dark:to-gray-700 shadow-lg rounded-lg flex flex-col w-full h-full">
        <PanelHeader
          className="panel-header"
          isExpanded={isExpanded}
          onToggleExpand={toggleExpand}
          onClose={onClose}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="flex-grow overflow-y-auto pt-12">
          <PanelContent instance={instance} activeTab={activeTab} project={project} />
        </div>
      </div>
    </Rnd>
  );
};

export default InstanceDetailsPanel;
