import React, { useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { FaUserCircle, FaBell } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';

const NavbarProfile = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { keycloak, authenticated } = useContext(AuthContext);

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleLogout = () => {
    keycloak.logout();
    return <Navigate to="/" replace />;
  };

  return (
    <div className="flex items-center space-x-4">
      {authenticated && <FaBell className="text-xl hover:text-gray-200 transition-colors duration-300 cursor-pointer" />}
      {authenticated ? (
        <div className="relative">
          <FaUserCircle className="text-2xl cursor-pointer hover:text-gray-200 transition-colors duration-300" onClick={toggleProfileMenu} />
          {isProfileMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white text-gray-800 rounded-lg shadow-lg z-10" role="menu">
              <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100" role="menuitem">Profile</Link>
              <Link to="/settings" className="block px-4 py-2 hover:bg-gray-100" role="menuitem">Settings</Link>
              <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-100" role="menuitem">Logout</button>
            </div>
          )}
        </div>
      ) : (
        <>
          <Link to="/login" className="bg-transparent border border-white py-2 px-4 rounded-lg hover:bg-white hover:text-green-600 transition-colors duration-300">Login</Link>
          <Link to="/signup" className="bg-white text-green-600 font-bold py-2 px-4 rounded-lg hover:bg-gray-200 transition-colors duration-300">Sign Up</Link>
        </>
      )}
    </div>
  );
};

export default NavbarProfile;
