import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../header/Navbar';
import ScrollToTop from '../ScrollToTop';
import Sidebar from './Sidebar';
import { AuthContext } from '../../context/AuthContext';
import Footer from '../footer/Footer';

const Layout = ({ children }) => {
  const location = useLocation();
  const isAuthRoute = ['/login', '/signup', '/forgot-password'].includes(location.pathname);
  const isDashboardRoute = location.pathname.startsWith('/dashboard');
  const { authenticated, login, register, forgotPassword } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (!authenticated && isAuthRoute) {
      if (location.pathname === '/login') {
        login();
      } else if (location.pathname === '/signup') {
        register();
      } else if (location.pathname === '/forgot-password') {
        forgotPassword();
      }
    }
  }, [authenticated, isAuthRoute, location.pathname, login, register, forgotPassword]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 dark:bg-black text-gray-900 dark:text-gray-100">
      <ScrollToTop />
      <Navbar onToggleSidebar={toggleSidebar} />
      <div className="flex flex-grow">
        {authenticated && isDashboardRoute && (
          <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        )}
        <main className={`flex-grow ${isDashboardRoute ? (sidebarOpen ? 'md:ml-64 ml-64 p-6 mt-10' : 'md:ml-10 ml-10 p-6 mt-10') : 'p-4'}`}>
          {children}
        </main>
      </div>
      {!isAuthRoute && !isDashboardRoute && <Footer />}
    </div>
  );
};

export default Layout;
