// src/components/layout/Sidebar.jsx
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaCloud, FaServer, FaImages, FaNetworkWired, FaDatabase, FaTachometerAlt, FaUserShield, FaCog, FaBars, FaTimes } from 'react-icons/fa';
import { LiaToggleOffSolid, LiaToggleOnSolid } from "react-icons/lia";

const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const isDashboardPage = location.pathname.startsWith('/dashboard');

  useEffect(() => {
    if (!isDashboardPage) {
      toggleSidebar(false);
    }
  }, [isDashboardPage, toggleSidebar]);

  return (
    isDashboardPage && (
      <aside className={`bg-green-900 text-white ${sidebarOpen ? 'w-64' : 'w-16'} min-h-screen shadow-lg flex flex-col fixed top-16 transition-all duration-300`} role="navigation" aria-label="Sidebar Navigation">
        <div className="flex justify-end p-4 pb-0">
          <button onClick={toggleSidebar} aria-label="Toggle Sidebar">
            {sidebarOpen ? <LiaToggleOnSolid className="text-2xl" /> : <LiaToggleOffSolid className="text-2xl" />}
          </button>
        </div>
        <nav className="flex-1 mt-4">
          <ul className="space-y-2">
            <li>
              <NavLink
                to="/dashboard/overview"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Overview"
              >
                <FaTachometerAlt />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Overview</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/instances"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Instances"
              >
                <FaServer />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Instances</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/images"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Images"
              >
                <FaImages />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Images</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/networks"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Networks"
              >
                <FaNetworkWired />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Networks</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/storage"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Storage"
              >
                <FaDatabase />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Storage</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/servers"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Servers"
              >
                <FaCloud />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Servers</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/security"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Security"
              >
                <FaUserShield />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Security</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/settings"
                className={({ isActive }) => `flex items-center space-x-3 p-3 rounded-lg transition-colors duration-200 ${isActive ? 'bg-green-700' : 'hover:bg-green-600'}`}
                aria-label="Settings"
              >
                <FaCog />
                <span className={`${sidebarOpen ? 'block' : 'hidden'}`}>Settings</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </aside>
    )
  );
};

export default Sidebar;
