import React from 'react';

const SearchFilter = ({ searchTerm, onSearchChange }) => (
  <input
    type="text"
    placeholder="Search and filter"
    className="flex-1 border border-gray-300 dark:border-gray-700 rounded px-4 py-2 mr-2 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100"
    value={searchTerm}
    onChange={(e) => onSearchChange(e.target.value)}
  />
);

export default SearchFilter;
