import React from 'react';

const Overview = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Overview</h1>
      <p>This is the overview section of the dashboard.</p>
    </div>
  );
};

export default Overview;
