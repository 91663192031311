import React, { createContext, useState, useEffect, useCallback } from 'react';
import keycloak from '../services/keycloak';
import Loading from '../components/Loading';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);

  const initKeycloak = useCallback(() => {
    keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    }).then(authenticated => {
      setAuthenticated(authenticated);
      setKeycloakInitialized(true);
    }).catch((error) => {
      console.error('Keycloak initialization error:', error);
      setAuthenticated(false);
      setKeycloakInitialized(true);
    });
  }, []);

  useEffect(() => {
    initKeycloak();

    const refreshToken = () => {
      keycloak.updateToken(70).catch(() => {
        setAuthenticated(false);
      });
    };

    if (keycloak.token) {
      const refreshInterval = setInterval(refreshToken, 60000); // Refresh every minute
      return () => clearInterval(refreshInterval);
    }
  }, [initKeycloak]);

  const login = () => {
    keycloak.login();
  };

  const register = () => {
    keycloak.register();
  };

  const forgotPassword = () => {
    keycloak.accountManagement();
  };

  const logout = () => {
    keycloak.logout();
  };

  if (!keycloakInitialized) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={{ keycloak, authenticated, login, register, forgotPassword, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
