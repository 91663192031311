import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useSortBy, useRowSelect, useGlobalFilter } from 'react-table';
import Select from 'react-select';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import ActionButton from './ActionButton';
import BulkActionDropdown from './BulkActionDropdown';
import SearchFilter from './SearchFilter';
import { FaPlay, FaStop, FaRedo, FaTrashAlt } from 'react-icons/fa';

const InstancesTable = ({ columns, data, handleBulkAction, handleBulkDelete, handleRowClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColumns, setSelectedColumns] = useState(columns.filter((col) => col.Header !== "IPv6").map(col => ({ label: col.Header, value: col.accessor })));
  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    const visibleCols = columns.filter(col => selectedColumns.some(selected => selected.value === col.accessor));
    setVisibleColumns(visibleCols);
  }, [selectedColumns, columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: visibleColumns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div onClick={(e) => e.stopPropagation()}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
        {
          id: 'actions',
          Header: 'Actions',
          Cell: ({ row }) => (
            <div className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
              <ActionButton icon={<FaPlay />} label="Start" onClick={() => handleBulkAction('start', [row.original])} />
              <ActionButton icon={<FaStop />} label="Stop" onClick={() => handleBulkAction('stop', [row.original])} />
              <ActionButton icon={<FaRedo />} label="Restart" onClick={() => handleBulkAction('restart', [row.original])} />
              <ActionButton icon={<FaTrashAlt />} label="Delete" onClick={() => handleBulkDelete([row.original])} />
            </div>
          ),
        },
      ]);
    }
  );

  useEffect(() => {
    setGlobalFilter(searchTerm || undefined);
  }, [searchTerm, setGlobalFilter]);

  const columnOptions = columns.map(col => ({ label: col.Header, value: col.accessor }));

  return (
    <div>
      <div className="flex mb-4 justify-between items-center">
        <SearchFilter searchTerm={searchTerm} onSearchChange={setSearchTerm} />
        <Select
          isMulti
          options={columnOptions}
          value={selectedColumns}
          onChange={setSelectedColumns}
          className="w-1/2"
        />
        <BulkActionDropdown
          handleBulkAction={handleBulkAction}
          handleBulkDelete={handleBulkDelete}
          selectedRows={selectedFlatRows.map(row => row.original)}
        />
      </div>
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-900">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${headerGroup.id}`}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                    key={`column-${column.id}`}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-white dark:bg-stone-950 divide-y divide-gray-200 dark:divide-gray-700">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={`row-${row.id}`} onClick={() => handleRowClick(row.original)} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200">
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                      key={`cell-${cell.column.id}-${i}`}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-700 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-300"
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-700 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-300"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700 dark:text-gray-300">
              Page <span className="font-medium">{pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-300"
              >
                First
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-300"
              >
                Previous
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-300"
              >
                Next
              </button>
              <button
                onClick={() => gotoPage(pageOptions.length - 1)}
                disabled={!canNextPage}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-300"
              >
                Last
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstancesTable;
