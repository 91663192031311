import parsePrometheusTextFormat from 'parse-prometheus-text-format';
import config from '../config';

const { protocol, host, port, token } = config.lxdServer;
const baseURL = `${protocol}://${host}:${port}`;

const useAuthHeaders = () => {
  return {
    'Authorization': `Bearer ${token}`,
  };
};

export const fetchMetrics = (instanceName, project) => {
  const headers = useAuthHeaders();
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/1.0/metrics?project=${project}`, { headers })
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        const json = parsePrometheusTextFormat(text);
        // Filter the metrics based on the instanceName
        const filteredMetrics = json.filter(metric => 
          metric.metrics.some(m => m.labels && m.labels.name === instanceName)
        );
        resolve(filteredMetrics);
      })
      .catch(reject);
  });
};
